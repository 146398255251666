import i18n from 'src/utils/i18n';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationStatus } from 'src/types/application';
import { TObjectWithLabel } from 'src/types/objects';

export interface ScoreFilter {
  value: number;
  isActive: boolean;
}

interface Filter {
  object: TObjectWithLabel;
  page: number;
  status: ApplicationStatus;
  verified: boolean;
  score: ScoreFilter;
}

export const FALLBACK_ALL: TObjectWithLabel = {
  objectId: 'all',
  label: i18n.t('All'),
};

const initialState: Filter = {
  object: FALLBACK_ALL,
  page: 1,
  status: ApplicationStatus.PRE_SELECTED,
  verified: false,
  score: {
    value: 80,
    isActive: false,
  },
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setObject: (state, action: PayloadAction<TObjectWithLabel>) => {
      state.object = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setStatus: (state, action: PayloadAction<ApplicationStatus>) => {
      state.page = 1;
      state.status = action.payload;
    },
    setVerified: (state, action: PayloadAction<boolean>) => {
      state.verified = action.payload;
    },
    setScore: (state, action: PayloadAction<ScoreFilter>) => {
      state.score = action.payload;
    },
  },
});

export const { setObject, setPage, setStatus, setVerified, setScore } = filterSlice.actions;

export default filterSlice.reducer;

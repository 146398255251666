import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import Visible from 'src/components/shared/Visible';
import { VariableHelper } from 'src/helper/variable';
import useObjectImages from 'src/hooks/queries/useObjectImages';
import { TObjectWithLabel } from 'src/types/objects';

type Props = {
  data: TObjectWithLabel;
};

const StyledWrapper = styled('div')({
  display: 'flex',
  margin: '1rem 0',
  gap: '1rem',
  '& .image': {
    width: '5rem',
    height: '5rem',
    backgroundColor: 'lightgray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0.5rem',
  },
  '& .details': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
});

const ObjectDetails = ({ data }: Props) => {
  const { t } = useTranslation();
  const { data: images, isLoading: imagesAreLoading } = useObjectImages({ id: data?.id });

  return (
    <StyledWrapper>
      <Visible when={!imagesAreLoading && !!images && !!images.length}>
        {images?.[0] ? (
          <img className="image" src={images[0].link} alt="object (apartment) image" />
        ) : null}
      </Visible>
      <div className="details">
        <div>
          <Visible when={VariableHelper.isDefined(data.rooms)}>
            {data.rooms} {t('Rooms')},
          </Visible>{' '}
          <Visible when={VariableHelper.isDefined(data.squareMeter)}>
            {data.squareMeter} {t('qm')}
          </Visible>
        </div>
        <div>
          <Visible when={VariableHelper.isDefined(data.address)}>{data.address}</Visible>
        </div>
        <div>
          <Visible when={VariableHelper.isDefined(data.rent)}>
            {data.rent?.toLocaleString()} € {t('Rent')},
          </Visible>{' '}
          <Visible when={VariableHelper.isDefined(data.deposit)}>
            {data.deposit?.toLocaleString()} € {t('Deposit')}
          </Visible>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default ObjectDetails;

import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from 'src/guards/authGuard/AuthGuard';
import GuestGuard from 'src/guards/authGuard/GuestGaurd';
import InviteApplicants from 'src/views/pages/portal/InviteApplicants';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import { ROUTES } from './constants';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

// pages
const FAQ = Loadable(lazy(() => import('../views/pages/portal/FAQ')));
const ApplicantOverview = Loadable(lazy(() => import('../views/pages/portal/ApplicantOverview')));
const ApplicantDetails = Loadable(lazy(() => import('../views/pages/portal/ApplicantDetails')));
const ObjectManagement = Loadable(lazy(() => import('../views/pages/portal/ObjectManagement')));
const CreateObject = Loadable(lazy(() => import('../views/pages/portal/CreateObject')));
const AppointmentSlots = Loadable(lazy(() => import('../views/pages/portal/AppointmentSlots')));

// authentication
const Login = Loadable(lazy(() => import('../views/authentication/auth/Login')));

const Router = [
  {
    path: ROUTES.ROOT,
    element: (
      <AuthGuard>
        <FullLayout />
      </AuthGuard>
    ),
    children: [
      { path: ROUTES.ROOT, element: <Navigate to={ROUTES.APPLICANT_OVERVIEW} /> },
      { path: ROUTES.APPLICANT_OVERVIEW, element: <ApplicantOverview /> },
      { path: `${ROUTES.APPLICANT_DETAILS()}`, element: <ApplicantDetails /> },
      { path: ROUTES.OBJECT_MANAGEMENT, element: <ObjectManagement /> },
      { path: ROUTES.CREATE_OBJECT, element: <CreateObject /> },
      { path: ROUTES.INVITE_APPLICANTS, element: <InviteApplicants /> },
      { path: ROUTES.APPOINTMENT_SLOTS(), element: <AppointmentSlots /> },
      { path: ROUTES.FAQ, element: <FAQ /> },
    ],
  },
  {
    path: ROUTES.AUTH,
    element: (
      <GuestGuard>
        <BlankLayout />
      </GuestGuard>
    ),
    children: [{ path: ROUTES.LOGIN, element: <Login /> }],
  },
];

export default Router;

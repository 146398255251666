import { FALLBACK_ALL, setObject } from 'src/store/FilterSlice';
import { useDispatch, useSelector } from 'src/store/Store';
import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useAllObjectsQuery from 'src/hooks/queries/useAllObjects';
import { TObject } from 'src/types/objects';
import useAuth from 'src/guards/authGuard/UseAuth';
import { PartnerHelper } from 'src/helper/partner';

type Props = {
  includeAll: boolean;
};

const ObjectsSelect = ({ includeAll }: Props) => {
  const { t } = useTranslation();
  const selectedObject = useSelector((state) => state.filter.object);
  const dispatch = useDispatch();
  const { user } = useAuth();
  const isPoha = PartnerHelper.isPoha(user?.email);
  const { isLoading, data } = useAllObjectsQuery({ onlyIncludeFinalSubmitted: !isPoha });

  const objects = useMemo(() => {
    if (isLoading || !data) return [];

    const withLabel = data.map((o) => ({
      ...o,
      label: o.name ?? o.objectId,
    }));

    if (includeAll) {
      withLabel.push({
        objectId: 'all',
        label: t('All'),
      });
    }

    return withLabel.sort((a, b) => {
      if (a.objectId === 'all') return -1;
      if (b.objectId === 'all') return 1;

      return 0;
    });
  }, [isLoading, data, t, includeAll]);

  // make sure to set the right language at init time
  useEffect(() => {
    const allObject = objects.find((o: TObject) => o.objectId === 'all');
    if (includeAll) {
      dispatch(setObject(allObject ?? FALLBACK_ALL));
    } else {
      dispatch(setObject(objects[0]));
    }
  }, [dispatch, objects, t, includeAll]);

  const handleChange = (event: SelectChangeEvent) => {
    const foundObject = objects.find((o: TObject) => o.objectId === (event.target.value as string));
    dispatch(setObject(foundObject ?? FALLBACK_ALL));
  };

  return (
    <FormControl sx={{ minWidth: 250 }}>
      <Select
        id="object-selection-applciant-list"
        value={selectedObject.objectId}
        onChange={handleChange}
      >
        {objects.map((o) => (
          <MenuItem value={o.objectId} key={o.objectId}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ObjectsSelect;

import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from './UseAuth';
import { useEffect } from 'react';
import { ROUTES } from 'src/routes/constants';
import { setRedirectPath } from 'src/store/AuthSlice';
import { dispatch } from 'src/store/Store';

const neutral = false;

const AuthGuard = ({ children }: any) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isAuthenticated && !neutral) {
      dispatch(setRedirectPath(pathname));
      navigate(ROUTES.LOGIN, { replace: true });
    }
  }, [isAuthenticated, navigate, pathname]);

  return children;
};

export default AuthGuard;

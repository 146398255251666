import { PropsWithChildren } from 'react';

type Props = {
  when: boolean;
};

const Visible = ({ when, children }: PropsWithChildren<Props>) => {
  if (!when) return null;

  return <>{children}</>;
};

export default Visible;

import { Box, styled, Typography } from '@mui/material';
import { IconBrandWhatsapp, IconCopy, IconMail, IconMessageCircle2 } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import TextArea from 'src/components/shared/TextArea';
import { useToast } from 'src/components/shared/toast/toast';

const StyledWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  '& .icons-wrapper': {
    display: 'flex',
    margin: '1rem 0',
    justifyContent: 'space-between',
  },

  '& .icon-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.5rem',
    cursor: 'pointer',
  },
  '& .custom-icon': {
    borderRadius: '100%',
    width: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    color: 'white',
  },
  '& .whatsapp-icon .custom-icon': {
    background: '#2AA81A',
  },
  '& .mail-icon .custom-icon': {
    background: '#2196F3',
  },
  '& .sms-icon .custom-icon': {
    background: '#57BB63',
  },

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',

    '& .whatsapp-icon, & .sms-icon': {
      display: 'none',
    },
  },
}));

type Props = {
  link: string;
};

const ShareLink = ({ link }: Props) => {
  const { t } = useTranslation();
  const { show } = useToast();

  const title = t('Rentcard application link');

  const handleShareViaWhatsapp = () => {
    const whatsappAddress = `whatsapp://send?text=${encodeURIComponent(`${title}: ${link}`)}`;
    window.location.href = whatsappAddress;
  };
  const handleShareViaMail = () => {
    const mailAddress = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(
      link,
    )}`;
    window.location.href = mailAddress;
  };
  const handleShareViaSMS = () => {
    const smsAddress = `sms:?body=${encodeURIComponent(`${title}: ${link}`)}`;
    window.location.href = smsAddress;
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    show('Link successfully copied to clipboard!');
  };

  return (
    <>
      <Typography variant="h4">{t('Share Link')}</Typography>
      <StyledWrapper>
        <div className="icons-wrapper">
          <div className="icon-wrapper whatsapp-icon" onClick={handleShareViaWhatsapp}>
            <div className="custom-icon">
              <IconBrandWhatsapp />
            </div>
            <div>WhatsApp</div>
          </div>
          <div className="icon-wrapper mail-icon" onClick={handleShareViaMail}>
            <div className="custom-icon">
              <IconMail />
            </div>
            <div>Mail</div>
          </div>
          <div className="icon-wrapper sms-icon" onClick={handleShareViaSMS}>
            <div className="custom-icon">
              <IconMessageCircle2 />
            </div>
            <div>SMS</div>
          </div>
        </div>
        <TextArea
          id="object-link-share"
          value={link}
          rows={1}
          multiline={false}
          endAdornment={
            <Box sx={{ cursor: 'pointer', marginLeft: '0.5rem' }} onClick={handleCopyLink}>
              <IconCopy color="#333333" />
            </Box>
          }
        />
      </StyledWrapper>
    </>
  );
};

export default ShareLink;

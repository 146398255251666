import { useQuery } from '@tanstack/react-query';
import { TObjectWithLabel } from 'src/types/objects';

import axios from 'axios';
import { API_URL } from './constants';
import { QueryHelper } from './helper';
import { ApplicationStatus } from 'src/types/application';
import { ObjectTransformer } from 'src/helper/transformer/object';

type Props = {
  onlyIncludeFinalSubmitted: boolean;
};

const useAllObjectsQuery = ({ onlyIncludeFinalSubmitted }: Props) => {
  return useQuery({
    queryKey: ['objects', onlyIncludeFinalSubmitted],
    queryFn: async () => {
      const accessToken = QueryHelper.getAccessToken();
      const wantedStatuses = [
        ApplicationStatus.LANDLORD_REJECTED,
        ApplicationStatus.PRE_SELECTED,
        ApplicationStatus.SELECTED,
      ];
      const statusQP = wantedStatuses.map((s) => `status=${s}`).join('&');
      const hasFinalSubmittedQP = onlyIncludeFinalSubmitted ? '&hasFinalSubmitted=true' : '';

      const response = await axios.get(`${API_URL}/objects/all?${statusQP}${hasFinalSubmittedQP}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return ObjectTransformer.addLabel(response.data) as TObjectWithLabel[];
    },
  });
};

export default useAllObjectsQuery;

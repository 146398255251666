export type ApplicationScoreQueryResult = {
  items: ApplicationScore[];
  pagination: Pagination;
};

export type ApplicationScoreMetadataQueryResult = {
  pagination: {
    total: Pagination;
    [ApplicationStatus.LANDLORD_REJECTED]?: Pagination;
    [ApplicationStatus.PRE_SELECTED]?: Pagination;
    [ApplicationStatus.SELECTED]?: Pagination;
  };
};

export type Pagination = {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  pageSize: number;
};

export type ApplicationScore = {
  applicantId: string;
  scores: Scores;
  scoreExpiryTime: string;
  submitDate: string;
  userData: UserData;
  userVerifications?: UserVerifications;
  application: Application;
};

export type Application = {
  id: string;
  date: string;
  status: ApplicationStatus;
  rent: number;
};

export type Scores = {
  overAllScore: number;
  identity: Identity;
  finHealth: {
    liquidity: number;
    cashflow: number;
    overdraft: number;
    overAllScore: number;
  };
  rental: {
    regularity: number;
    validation: number;
    history: number;
    insurance: number;
    overAllScore: number;
  };
  employment: {
    salary: number;
    limitations: number;
    validation: number;
    overAllScore: number;
  };
  specificScores: {
    name: string;
    description: string;
    affordability: number;
    balance: number;
    overAllScore: number;
  };
};

export type socialSurvey = {
  happy_life: number;
  open_experience: number;
  live_alone: number;
  sustainability: number;
  tasks: number;
  quiet_atmosphere: number;
  interaction_housemates: number;
  independence: number;
  enjoyable_time: number;
  reference_person: number;
  events_cultures: number;
  clean_environment: number;
};

export type Identity = {
  name: string;
  email: string;
  mobile: string;
};

export type submissionData = {
  applicationId: string;
  name: string;
  email: string;
  pets: boolean;
  petsDescription: string;
  socialScore: number;
  income: number;
  guarantorIncome: number;
};

export type AddressObject = {
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  description: string;
};

export type UserData = {
  contact?: {
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    firstName?: string;
  };
  insurance?: {
    liabilityInsurance?: boolean;
    propertyInsurance?: boolean;
  };
  employment?: {
    netIncome?: number;
    employmentType?: string;
    limitedTerm?: boolean;
    probation?: boolean;
    employedDate?: string;
    position?: string;
    employerName?: string;
  };
  householdSize?: {
    adults?: number;
    kids?: number;
  };
  selfDescription?: {
    selfDescription?: string;
  };
  partnerQuestions?: {
    crimes?: boolean;
    deposit_insurance?: boolean;
    prev_foreclosure?: boolean;
  };
  socialSurvey?: socialSurvey;
  personal?: {
    address: AddressObject | string;
    residencePermit?: boolean;
    insolvency?: boolean;
    pets?: string;
    missedRent?: boolean;
    moveInDate?: string;
    birthDate?: string;
    smoker?: boolean;
    idNumber?: string;
  };
};

export type UserVerifications = {
  emailVerified: boolean;
  identityVerified: boolean;
  rentVerified: boolean;
  incomeVerified: boolean;
};

export enum ApplicationStatus {
  LANDLORD_REJECTED = 'landlordRejected',
  SELECTED = 'selected',
  PRE_SELECTED = 'preSelected',
}

export type UpdateApplicationDto = {
  id: string;
  status: ApplicationStatus;
};

export type requestTimeslotsDto = {
  id: string;
  landlordMessage: string;
};

export type RequestAdditionalDocumentsDto = {
  id: string;
  documentBody: string;
  firstName: string;
};

export enum AssessmentStatus {
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
  INVALID = 'invalid',
}

import { Box, Drawer } from '@mui/material';
import { useContext, useState } from 'react';
import type {
  IBottomSheetProvider,
  IBottomSheetContext,
  IBottomSheetContextProps,
  IUseBottomSheetProvider,
} from './types';
const DURATION = 300;
import { createContext } from 'react';

export const BottomSheetContext = createContext<IBottomSheetContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  show: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  close: () => {},
} as IBottomSheetContext);

const useBottomSheetProvider = (): IUseBottomSheetProvider => {
  const [dataManager, setDataManager] = useState<IBottomSheetContextProps[]>([]);
  const [visibilityManager, setVisibilityManager] = useState<boolean[]>([]);

  const close = (): void => {
    setVisibilityManager((prev) => {
      prev.pop();
      return [...prev];
    });

    // Prevent data to disappear suddenly on close
    setTimeout(
      () => {
        setDataManager((prev) => {
          prev.pop();
          return [...prev];
        });
      },
      dataManager.length === 1 ? DURATION : 0,
    );
  };

  const show = (data: IBottomSheetContextProps): void => {
    if (data && data?.content) {
      setVisibilityManager((prev) => [...prev, true]);
      setDataManager((prev) => [...prev, data]);
    } else {
      close();
    }
  };

  // Get title and content from last item
  const data = dataManager[dataManager.length - 1];

  return {
    data,
    show,
    close,
    visibilityManager,
  };
};

export const BottomSheetProvider = ({ children }: IBottomSheetProvider) => {
  const { visibilityManager, data, ...actions } = useBottomSheetProvider();

  return (
    <BottomSheetContext.Provider value={actions}>
      {children}

      <Drawer open={!!visibilityManager?.length ?? 0} onClose={actions.close} anchor="bottom">
        <Box padding="1rem">{data?.content}</Box>
      </Drawer>
    </BottomSheetContext.Provider>
  );
};

export const useBottomSheet = () => {
  const dialog = useContext(BottomSheetContext);

  if (!dialog) {
    throw new Error('useBottomSheet must be used within the BottomSheet Provider');
  }

  return dialog;
};

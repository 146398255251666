import { styled, TextField } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  id: string;
  rows?: number;
  value: string;
  multiline?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  endAdornment?: ReactNode;
};

const StyledTextArea = styled(TextField)({
  width: '100%',
  '& :before': {
    display: 'none',
  },
  '& > .MuiInputBase-root': {
    borderRadius: 24,
    padding: 16,
    overflow: 'hidden',
  },
  '& .MuiInputBase-input': {
    padding: 0,
  },
});

const TextArea = ({ id, rows = 4, value, endAdornment, onChange, multiline = true }: Props) => {
  return (
    <StyledTextArea
      id={id}
      multiline={multiline}
      rows={rows}
      value={value}
      onChange={onChange}
      variant="filled"
      InputProps={{
        endAdornment: endAdornment,
        disableUnderline: true,
      }}
    />
  );
};

export default TextArea;

import { Box, styled, Typography } from '@mui/material';
import { IconCopy } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextArea from 'src/components/shared/TextArea';
import { useToast } from 'src/components/shared/toast/toast';

const StyledWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
});

type Props = {
  link: string;
};

const ObjectMessage = ({ link }: Props) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  const messageTemplate = t('Share Message Template');
  const [message, setMessage] = useState<string>(messageTemplate);
  const { show } = useToast();

  useEffect(() => {
    const messageWithLink = messageTemplate.replace('{{link}}', link);

    setMessage(messageWithLink);
  }, [messageTemplate, t, resolvedLanguage, link]);

  const handleCopyTemplate = () => {
    navigator.clipboard.writeText(message);
    show('Text successfully copied to clipboard!');
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  return (
    <StyledWrapper>
      <Typography variant="h4">{t('Share Message')}</Typography>
      <TextArea
        id="object-message"
        rows={12}
        value={message}
        onChange={handleTextChange}
        endAdornment={
          <Box sx={{ alignSelf: 'start', cursor: 'pointer' }} onClick={handleCopyTemplate}>
            <IconCopy />
          </Box>
        }
      />
    </StyledWrapper>
  );
};

export default ObjectMessage;

import { FC, Suspense } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { ThemeSettings } from './theme/Theme';
import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import { BottomSheetProvider } from './components/shared/bottom-sheet/bottom-sheet';
import { ToastProvider } from './components/shared/toast/toast';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App: FC = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();

  return (
    <ThemeProvider theme={theme}>
      <BottomSheetProvider>
        <ToastProvider>
          <CssBaseline />
          <ScrollToTop>{routing}</ScrollToTop>
        </ToastProvider>
        <ToastContainer />
      </BottomSheetProvider>
    </ThemeProvider>
  );
};

export default function WrappedApp() {
  return (
    <Suspense fallback="...is loading">
      <App />
    </Suspense>
  );
}

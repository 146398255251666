import { useNavigate } from 'react-router-dom';
import useAuth from './UseAuth';
import { useEffect } from 'react';
import { useSelector } from 'src/store/Store';
import { ROUTES } from 'src/routes/constants';

const GuestGuard = ({ children }: any) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const redirectPath = useSelector((state) => state.auth.redirectPath);

  useEffect(() => {
    if (isAuthenticated) {
      if (redirectPath) {
        navigate(redirectPath, { replace: true });
      } else {
        navigate(ROUTES.ROOT, { replace: true });
      }
    }
  }, [isAuthenticated, navigate, redirectPath]);

  return children;
};

export default GuestGuard;

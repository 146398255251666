import { styled, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ObjectsSelect from './objects-select';
import { IconPencil } from '@tabler/icons';
import { useNavigate } from 'react-router';
import { ROUTES } from 'src/routes/constants';
import { TObjectWithLabel } from 'src/types/objects';

const StyledWrapper = styled('div')({
  marginTop: '1rem',
  width: '100%',
  display: 'flex',
  gap: '1rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (min-width: 600px)': {
    justifyContent: 'start',
  },
});

const EditWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  cursor: 'pointer',
  '.edit-text': {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    '.edit-text': {
      display: 'block',
    },
  },
}));

type Props = {
  includeAll?: boolean;
  data?: TObjectWithLabel;
};

const ObjectSection = ({ includeAll = true, data }: Props) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleEditObject = () => {
    if (!data) return;
    navigate({ pathname: ROUTES.OBJECT_MANAGEMENT, search: `?objectId=${data.objectId}` });
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <StyledWrapper>
        <Typography fontSize={16} sx={{ fontWeight: 'bold' }}>
          {t('Object')}
        </Typography>
        <ObjectsSelect includeAll={includeAll} />
      </StyledWrapper>
      {data && (
        <EditWrapper onClick={handleEditObject}>
          <Typography color="primary" className="edit-text">
            {t('Edit')}
          </Typography>
          <IconPencil color="#1e56e3" />
        </EditWrapper>
      )}
    </Box>
  );
};

export default ObjectSection;

import { useQuery } from '@tanstack/react-query';

import axios from 'axios';
import { API_URL } from './constants';
import { QueryHelper } from './helper';
import { TObjectImage } from 'src/types/objects';
import { DocumentCategoryEnum } from 'src/types/document';

type Props = {
  id: string | undefined | null;
};

const useObjectImages = ({ id }: Props) => {
  return useQuery({
    queryKey: ['object-images', id],
    queryFn: async () => {
      const accessToken = QueryHelper.getAccessToken();
      const idQP = `name=${id}`;
      const categoryQP = `&category=${DocumentCategoryEnum.PARTNER_OBJECT_IMAGE}`;

      const response = await axios.get(`${API_URL}/documents/links?${idQP}${categoryQP}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return response.data as TObjectImage[];
    },

    enabled: !!id && id !== null,
  });
};

export default useObjectImages;

import { TObject, TObjectWithLabel } from 'src/types/objects';

export class ObjectTransformer {
  static addLabel(objects: TObject | TObject[]): TObjectWithLabel | TObjectWithLabel[] {
    if (Array.isArray(objects)) {
      return objects.map((o) => this.addLabelOnObject(o));
    }

    return this.addLabelOnObject(objects);
  }

  private static addLabelOnObject(object: TObject): TObjectWithLabel {
    return {
      ...object,
      label: object.name ?? object.objectId,
    };
  }
}

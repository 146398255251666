import { TFunction } from 'i18next';

export enum DocumentCategoryEnum {
  PARTNER_OBJECT_IMAGE = 'partner.object.image',
}

export type IDocuments = {
  id?: string;
  category?: string;
  originName?: string;
  name?: string;
  mimeType?: string;
  handlerId?: string;
  handlerType?: string;
  fileSize?: number;
  scheduledDelete?: Date;
  partnerIdentifier?: string;
};

export type IDocumentsWithTitle = IDocuments & {
  title: ILanguageRecordObject;
};

export type ILanguageRecordObject = {
  en: string;
  de: string;
};

export type EmailTextProps = {
  t: TFunction;
  items: { label: string; checked: boolean }[];
};

export type BottomSheetComponentDocumentsProps = {
  items?: { label: string; checked: boolean }[];
  bodyText?: string;
  firstName: string;
  id: string;
};

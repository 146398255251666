import { Snackbar } from '@mui/material';
import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { IToastContext, IToastProvider } from './types';

export const ToastContext = createContext<IToastContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  show: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  close: () => {},
} as IToastContext);

const useToastProvider = (): IToastProvider => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');

  const close = () => {
    setIsOpen(false);
    setTimeout(() => {
      setMessage('');
    }, 100);
  };

  const show = (message: string, timeout?: number) => {
    setMessage(message);
    setIsOpen(true);
    setTimeout(() => {
      close();
    }, timeout ?? 5000);
  };

  return {
    isOpen,
    message,
    close,
    show,
  };
};

export const ToastProvider = ({ children }: PropsWithChildren) => {
  const toast = useToastProvider();

  return (
    <ToastContext.Provider value={toast}>
      {children}
      <Snackbar
        open={toast.isOpen}
        message={toast.message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClick={toast.close}
      />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const toast = useContext(ToastContext);

  if (!toast) {
    throw new Error('useToast must be used within the Toast Provider');
  }

  return toast;
};

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import AuthReducer from './AuthSlice';

import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from 'react-redux';
import FilterReducer from './FilterSlice';
import SortReducer from './SortSlice';
import AuthSlice from './AuthSlice';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    filter: FilterReducer,
    sort: SortReducer,
    auth: AuthSlice,
  },
});

const rootReducer = combineReducers({
  customizer: CustomizerReducer,
  filter: FilterReducer,
  sort: SortReducer,
  auth: AuthReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const { dispatch } = store;
export const useDispatch = () => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector;

export default store;

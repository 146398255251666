// Fonts
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/700.css';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';

const fontFamily = [
  'Roboto',
  'system-ui',
  '-apple-system',
  'Segoe UI',
  'Helvetica',
  'Arial',
  'sans-serif',
].join(',');
const fontFamilyHeaders = [
  'Poppins',
  'Roboto',
  'system-ui',
  '-apple-system',
  'Segoe UI',
  'Helvetica',
  'Arial',
  'sans-serif',
].join(',');

const typography: any = {
  h1: {
    fontFamily: fontFamilyHeaders,
    fontWeight: 400,
    fontSize: '2.125rem',
    lineHeight: '2.75rem',
  },
  h2: {
    fontFamily: fontFamilyHeaders,
    fontWeight: 400,
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
  },
  h3: {
    fontFamily: fontFamilyHeaders,
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
  },
  h4: {
    fontFamily: fontFamilyHeaders,
    fontWeight: 500,
    fontSize: '1.3125rem',
    lineHeight: '1.8rem',
  },
  h5: {
    fontFamily: fontFamilyHeaders,
    fontWeight: 500,
    fontSize: '1.125rem',
    lineHeight: '1.6rem',
  },
  h6: {
    fontFamily: fontFamilyHeaders,
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  button: {
    fontFamily: fontFamily,
    textTransform: 'capitalize',
    fontWeight: 400,
  },
  body1: {
    fontFamily: fontFamily,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.334rem',
  },
  body2: {
    fontFamily: fontFamily,
    fontSize: '1rem',
    letterSpacing: '0rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
  subtitle1: {
    fontFamily: fontFamily,
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  subtitle2: {
    fontFamily: fontFamily,
    fontSize: '0.875rem',
    fontWeight: 400,
  },
  itemlist: {
    fontFamily: fontFamily,
    fontSize: '1.25rem',
    fontWeight: 400,
  },
};

export default typography;

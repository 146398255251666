import i18n from 'src/utils/i18n';

export class VariableHelper {
  static getYesNo(value: boolean) {
    return value === true ? i18n.t('Yes') : i18n.t('No');
  }

  static getYesNoOrValue(value: string | number | boolean) {
    if (typeof value === 'boolean') {
      return VariableHelper.getYesNo(value);
    }

    return value;
  }

  static isDefined<T>(value: T | undefined | null): boolean {
    return value !== undefined && value !== null;
  }
}

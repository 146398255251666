import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';
import Visible from 'src/components/shared/Visible';

interface HeaderType {
  title: string;
  before?: ReactNode;
}

const Header = ({ title, before }: HeaderType) => (
  <Box sx={{ display: 'flex', my: 2, alignItems: 'center' }}>
    <Visible when={!!before}>{before}</Visible>
    <Typography variant="h3">{title}</Typography>
  </Box>
);

export default Header;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  redirectPath: string | null;
}

const initialState: AuthState = {
  redirectPath: null,
};

const filterSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setRedirectPath: (state, action: PayloadAction<string | null>) => {
      state.redirectPath = action.payload;
    },
  },
});

export const { setRedirectPath } = filterSlice.actions;

export default filterSlice.reducer;

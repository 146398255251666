import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SORT_OPTIONS, SORT_TYPE } from 'src/types/sort';

export interface Sort {
  field: SORT_OPTIONS | null;
  type: SORT_TYPE | null;
}

const initialState: Sort = {
  field: null,
  type: null,
};

const sortSlice = createSlice({
  name: 'sort',
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<{ field: SORT_OPTIONS; type: SORT_TYPE } | null>) => {
      state.field = action.payload?.field ?? null;
      state.type = action.payload?.type ?? null;
    },
  },
});

export const sortActions = {
  setSort: sortSlice.actions.setSort,
};
export default sortSlice.reducer;

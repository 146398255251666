export const ROUTES = {
  ROOT: '/',
  APPLICANT_OVERVIEW: '/applicant-overview',
  APPLICANT_DETAILS: (id?: string) => `/applicant-details/${id ?? ':id'}`,
  OBJECT_MANAGEMENT: '/object-management',
  CREATE_OBJECT: '/create-object',
  INVITE_APPLICANTS: '/invite-applicants',
  APPOINTMENT_SLOTS: (id?: string) => `/appointment-slots/${id ?? ':id'}`,
  FAQ: '/faq',
  AUTH: '/auth',
  LOGIN: '/auth/login',
} as const;
